var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "left" },
          attrs: {
            height: "fit-content",
            width: "90%",
            title: "Select Order",
            visible: _vm.dialogSelectOrder,
          },
          on: {
            open: function ($event) {
              return _vm.getOrders(50, 0)
            },
            close: _vm.handleOrderDialogClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.orderTableLoading,
                      expression: "orderTableLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.orderTableData,
                    height: "48vh",
                    "header-cell-style": { color: "#909399" },
                    "cell-style": { padding: "0", height: "80px" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "55" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: scope.row },
                                model: {
                                  value: _vm.orderRadioData,
                                  callback: function ($$v) {
                                    _vm.orderRadioData = $$v
                                  },
                                  expression: "orderRadioData",
                                },
                              },
                              [_c("i")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Company", "show-overflow-tooltip": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.myCompanyId ===
                                    scope.row.sellerSysOrganizationId
                                    ? scope.row.buyerCompanyName
                                    : scope.row.sellerCompanyName
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Order No",
                      prop: "salesOrderNo",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isSellerView || _vm.isCommissionInvoice
                                    ? scope.row.salesOrderNo
                                    : scope.row.purchaseOrderNo
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label:
                        _vm.orderType !== _vm.ORDER_TYPE.EX_WHARF
                          ? "Vessel"
                          : "Terminal",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.orderType === _vm.ORDER_TYPE.DELIVERED ||
                            _vm.orderType === _vm.ORDER_TYPE.BARGING_NOMINATION
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.vesselName || "-") +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.terminal || "-") +
                                      " "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Port", "show-overflow-tooltip": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.port || "-") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Payment Term",
                      prop: "paymentTerm",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.capitalizeFirstLetter(
                                    scope.row.paymentTerm,
                                    "_"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              !_vm.orderTableLoading
                ? _c(
                    "div",
                    {
                      staticClass: "div-main-list-page-table-pagination",
                      staticStyle: {
                        "text-align": "right",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "page-size": _vm.orderPagination.pageSize,
                          "page-sizes": [5, 10, 20, 50, 100],
                          "current-page": _vm.orderPagination.pageNumber,
                          layout: "total, sizes, prev, pager, next",
                          total: _vm.orderPagination.total,
                        },
                        on: {
                          "size-change": _vm.handleOrderPageSizeChange,
                          "current-change": _vm.handleOrderPageChange,
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.orderPagination,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.orderPagination,
                              "pageSize",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "el-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { outline: "", round: "" },
                      on: { click: _vm.handleOrderDialogClose },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.orderRadioData,
                        round: "",
                        type: "primary",
                      },
                      on: { click: _vm.handleConfirmOrderClick },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }